export const hcpCFT: any = {
  mainMenu: "Main Navigational Menu HCP GCSO - Retina Global",
  footerMenu: "Footer Navigation Menu HCP GCSO - Retina Global",
  footer: "Retina Global Footer HCP - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hCPSwitcher: "HCP Switcher Modal Pop Up - Global",
  topNav: "Top Navigational Menu HCP US - Global",
  siteLogo: "Site logos - Global",
  tabPara: "Tab Content - New - CFT - HCP - Retina Global",
  footerReference: "Reference - Considerations For Testing  - Global",
  banner: "Banner - HTML - Consideration Testing - HCP - US Global",
  backToTopGTM: "Back to Top - considerations for testing",
  tabAttrs: {
    stepContent: "Step",
    tabGTMLabels: [{
      tabButtonGTM: {
        'data-gtm-event-label': 'Genetic Testing Process Overview Tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [
        {
          iconGTMs: [
            {
              'data-gtm-event-label': '1 Clinically examine your patient',
              'className': 'gtm-accordion',
              'aria-label': '1 Clinically examine your patient'
            },
            {
              'data-gtm-event-label': '2 Partner with a genetic counselor or genetic specialist',
              'className': 'gtm-accordion',
              'aria-label': '2 Partner with a genetic counselor or genetic specialist'
            },
            {
              'data-gtm-event-label': '3 Order the appropriate genetic test',
              'className': 'gtm-accordion',
              'aria-label': '3 Order the appropriate genetic test'
            },
            {
              'data-gtm-event-label': '4 Collect a saliva or blood sample',
              'className': 'gtm-accordion',
              'aria-label': '4 Collect a saliva or blood sample'
            },
            {
              'data-gtm-event-label': '5 Discuss the results with your patient',
              'className': 'gtm-accordion',
              'aria-label': '5 Discuss the results with your patient',
            }
          ],
          prevBtnGTMs: [
            {
              'data-gtm-event-label': 'Step 1 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 1'
            },
            {
              'data-gtm-event-label': 'Step 2 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 2'
            },
            {
              'data-gtm-event-label': 'Step 3 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 3'
            },
            {
              'data-gtm-event-label': 'Step 4 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 4'
            },
            {
              'data-gtm-event-label': 'Step 5 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 5'
            }
          ],
          nextBtnGTMs: [
            {
              'data-gtm-event-label': 'Step 1 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 1'
            },
            {
              'data-gtm-event-label': 'Step 2 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 2'
            },
            {
              'data-gtm-event-label': 'Step 3 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 3'
            },
            {
              'data-gtm-event-label': 'Step 4 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 4'
            },
            {
              'data-gtm-event-label': 'Step 5 Button',
              'className': 'gtm-accordion',
              'aria-label': 'Go to step 5'
            }
          ]
        }
      ]
    },
    {
      tabButtonGTM: {
        'data-gtm-event-label': 'Discussing Results Tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [{
        iconGTMs: [
          {
            'data-gtm-event-label': 'Before the Genetic Test',
            'className': 'gtm-accordion',
            'aria-label': 'Before the genetic test'
          },
          {
            'data-gtm-event-label': 'After the Genetic Test',
            'className': 'gtm-accordion',
            'aria-label': 'After the genetic test'
          },
          {
            'data-gtm-event-label': 'If a Genetic Test is Negative or Inconclusive',
            'className': 'gtm-accordion',
            'aria-label': 'If a Genetic Test is Negative or Inconclusive'
          }
        ]
      }]
    }
    ]
  },
  footerClassName: "considerations-for-testing-footer"
};