import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";
import { font } from "../../../css/common/variables";

export const ConsiderationsForTesting = styled.div`
  .headerwraphead {
    .topnav {
      .professional-icon-wrapper {
        a {
          @media ${device.laptopMedium} {
            font-size: 1.4rem;
            line-height: 1.8rem;
          }

          @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
            font-size: 1.8rem;
            line-height: 2.6rem;
          }
        }
      }
    }
  }
  .consideration-testing {
    .carouselSlides {
      .slick-slider {
        max-width: 950px;
        margin: 0px auto;

        .slick-prev,.slick-next {
          display: none !important;
        }

        .slick-list {
          margin: 0;

          .slick-track {
            .slick-slide {
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

	&.hcp-consideration-container {

		div.video-container-consideration-for-testing {
      padding-left: 3rem;
      padding-right: 3rem;
      @media ${device.tablet} {
        padding-left: 5rem;
        padding-right: 5rem;
      }
      @media ${device.desktopsignup} {
        padding-left: 5rem;
        padding-right: 5rem;
      }
      @media screen and (min-width: 1365px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .video-section {
        padding: 48px 10px 0;
      }
		}

		#footerRefConId {
			ol {
				padding-left: 5px;

				li {
					margin: 0 0 20px 20px;
				padding: 0 0 0 16px;

					@media ${device.laptopMedium} {
						text-align: left;
						font-size: 1.4rem;
						line-height: 1.8rem;
						margin: 0 0 5px 20px;
					}
				}
			}
		}

		footer {
			padding: 0 5rem !important;

			@media ${device.laptopMedium} {
				padding: 0 2rem !important;
			}

			.show-hide {
				padding-top: 0;
			}
			.left-footer {
				padding-top: 0px;

				@media ${device.laptopMedium} {
					padding-top: 0;
				}
			}
			.col-lg-4 {
		@media ${device.laptop}{
            padding-top: 5px;
           }

				@media ${device.laptopMedium} {
					padding-top: 0;
				}
			}
			.o-copyright {
				padding-top: 20px;
			}
			.do-not-sell {
				.gtm-footer {
					color: #00FFD9 !important;
				}
			}
		}

	.clinical-trials-banner {
    &:after{
      @media ${device.mobileMedium}{
        display:none;
      }
    }
    height: 100%;
			.internal-banner {
        @media ${device.mobileMedium} {
          height: 360px;
        }
				&:after {
          top: unset;
          @media ${device.laptopMedium} {
            height: 32px;
          }
				}
        .internal-banner__image {
          .desktop-image {
            @media ${device.tablet} and ${device.laptopMedium} {
              height: 500px;
            }
          }
          .mobile-image {
              img {
                @media ${device.mobileMedium} {
                  height: 360px;
                }
              }
          }
        }
        .internal-banner__contant {
          .o-container {
            @media ${device.ipadLandscapemin} {
              padding: 0 3rem;
              max-width: 1188px;
            }
            h1 {
              font-family: "Gotham", Arial, Helvetica, sans-serif;
              line-height: 6.4rem;
              @media ${device.laptopMedium} {
                line-height: 3.8rem;
              }
              @media ${device.ipadLandscapemin} {
                padding-left: 25px;
              }
            }
          }
        }
			}

	}
	.footercontainer {
		margin-top: 0;
			.row {
				padding-top: 0px;
			}

			.c-hide-references {
				padding-top: 30%;

				@media ${device.smalldeskstrt} {
					padding-top: 27%;
				}

				@media ${device.desktopStart} {
					padding-top: 28%;
				}

				@media ${device.ipadLandscape} {
					padding: 10rem 0 0;
				}
			}

	}
	footer {
			max-width: 1188px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 3rem;
			@media ${device.laptopMedium} {
				padding: 0 5rem;
			}
			@media ${device.ipadLandscape} {
				max-width: 718px;
			}
			@media ${device.laptopPros} {
				padding: 0 1rem;
			}
	}

		.considerations-for-testing-gene-mods {
			padding: 90px 0 80px;
			background: #f7f7f7;

			@media ${device.laptopMedium} {
				padding-bottom: 48px !important;
			padding-top: 48px !important;
			}

			.o-container {
				@media ${device.laptopMedium} {
					padding: 0;
				}
			}

			h2 {
        @media ${device.mobileMedium} {
          width: 80% !important;
          margin: auto;
        }
				margin: 30px 0;
				text-align: center;
				font-size: 4.2rem;
				font-weight: 600;
				line-height: 5rem;
				color: #000000;
        font-family: ${font.gotham_bold};
				@media ${device.laptopMedium} {
					font-size: 2.5rem;
				  line-height: 3rem;
					margin-top: 0 !important;
          width: 50%;
          margin: auto;
				}
        @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
          width: 69%;
          margin: auto;
        }
        @media ${device.desktopsignup} {
          width: 56%;
          margin: auto;
        }
        @media ${device.desktopStart} {
          width: 54%;
          margin: auto;
        }
			}

			.faq-block {
				align-items: center;
			  margin: 50px 0 0;
				max-width: 1440px;
				padding-top: 44px;
				display: flex;
				flex-wrap: wrap;
				position: relative;

				.genes-faq-content__left {
					@media ${device.laptopMedium} {
						width: 100% !important;
						margin: 0 !important;
						padding: 0;
					}
				}

				.genes-faq-media__right {
					display: grid;
					align-items: center;
					width: 50%;
					padding-right: calc((100% - 1168px) / 2);
					padding-left: 18px;

					@media ${device.laptopMedium} {
						padding-left: 0px;
						width: 100%;
						padding: 20px 0 0;
					}

					.o-header {
						color: #8000BE;
						font-size: 2.7rem;
						font-weight: 600;
						line-height: 4.7rem;
						margin-bottom: 0;

						@media ${device.laptopMedium} {
							margin-top: 15px;
							text-align: center;
							font-size: 2rem;
							line-height: 2.2rem;
						}

						sup {
							font-size: 60%;
						}
					}

					.listing-section {
						margin-top: 30px !important;
						margin: auto;
						display: table;
						padding: 0;

						@media ${device.laptopMedium} {
							padding-left: 30px;
							padding-right: 30px;
							margin-top: 0 !important;
						}

						li {
							display: inline-block;
							position: relative;
							padding: 2px 0 12px 25px;
							color: #000000;
							font-size: 2.2rem;
							font-weight: 500;
							line-height: 3.2rem;
							list-style: none;
							font-family: 'Gotham-Book';

							@media ${device.laptopMedium} {
								padding: 5px 0 5px 25px;
								font-size: 1.8rem;
							  line-height: 2.6rem;
								text-align: left;
								display: block;
							}

							&:before {
								top: 16px;
								content: "";
								position: absolute;
								background-color: #8000BE;
								width: 8px;
								height: 8px;
								margin: auto;
								left: 0;

								@media ${device.laptopMedium} {
									top: 13px;
								}
							}
								p {
                  @media ${device.laptopMedium} {
                    margin:0;
                  }
								}
						}
					}
				}
				a {
					&:hover {
						font-weight: bold;
					}
				}
			}

			~.o-container {
				@media ${device.laptopMedium} {
					padding: 0 2rem;
				}
				@media ${device.laptopPros} {
					padding: 0 3rem !important;
				}
				@media (max-width: 1359.98px) {
					padding: 0 5rem;
				}
			}
		}

		#consideration-find-a-gen-con {
			> .o-container {
				@media ${device.laptopMedium} {
					padding: 0 3rem;
				}
				@media  ${device.tablet} and ${device.laptopair2max} {
					padding: 0 5rem;
				}
				.step-info-box {
					@media ${device.laptopMedium} {
						padding: 30px;
					}
					@media  ${device.tablet} and ${device.laptopair2max} {
						padding: 30px 100px 30px;
					}
				}
			}
      .info-box {
        p.o-paragraph {
          text-align: left;
          padding-top: 0;
          font-size: 1.8rem;
          line-height: 2.2rem;
          font-family: ${font.gotham};
          @media ${device.ipadLandscapemin} {
            font-size: 2.2rem;
            line-height: 3.2rem;
          }
        }
        span {
          font-size: 2rem;
          line-height: 2.4rem;
          font-family: ${font.gotham};
          @media ${device.ipadLandscapemin} {
            font-size: 2.7rem;
            line-height: 3.8rem;
          }
        }
      }
		}

		.info-box-2 {
			p {
				width: 84%;
			  margin: 0 auto;
        font-size: 2.2rem !important;
        line-height: 3.2rem !important;
        font-family: ${font.gotham} !important;

				@media ${device.laptopMedium} {
					width: 100%;
          font-size: 2rem !important;
          line-height: 2.4rem !important;
				}

				span {
					display: unset !important;
					padding-bottom: 0 !important;
				}
			}
		}
		.info-box {
			max-width: 1120px !important;
		}
		.step-info-box {
			max-width: 1020px;
			margin-left: auto;
			margin-right: auto;
			margin: 120px auto 0;
			flex-wrap: wrap;
			width: 100%;
			display: flex;
			align-items: center;
			background-color: #300064;
			padding: 40px 70px;
			position: relative;

			@media ${device.laptopMedium} {
				padding: 30px 10px 30px;
				margin-top: 48px !important;
				margin: 50px auto 0;
				justify-content: center;
				display: block;
				padding-left: 2rem;
			  padding-right: 2rem;
				max-width: 354px;
			}

			@media  ${device.tablet} and ${device.laptopair2max} {
				max-width: 618px;
			}
			p, h2 {
				text-align: center;
				margin: 0 30px 0;
				color: #fff;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				margin: 0 auto;

				@media ${device.laptopMedium} {
					text-align: center;
					font-size: 1.8rem;
				  line-height: 2.6rem;
					width: 82%;
				  margin: 0 auto;
				}

				span {
					color: #00FFD9;
					padding-top: 0;
				  display: flex;
					padding-bottom: 12px;

					@media ${device.laptopMedium} {
						display: unset;
					}
				}

				sup {
					font-size: 60%;
				}
			}
			a {
				&:after {
					width: 18px;
				  height: 18px;

					@media ${device.ipadLandscapemin} and ${device.minideskend} {
						top: 19px;
						margin-left: 11px;
					}

					@media ${device.laptopPros} {
						top: 13px;
					}
					@media ${device.tablet} and ${device.laptopMedium} {
						top: 14px;
					}
					@media ${device.laptop} {
						top: 18px;
						margin-left: 11px;
					}
				}
			}
		}
		.considerations-for-testing-location-box {
			img {
				position: absolute;
        left: 100px;
        width: 68px;
			  top: 80px;

				@media ${device.laptopMedium} {
					position: relative !important;
					top: 0;
					left: 0 !important;
					display: block;
					margin: auto;
					width: 40px;
				}
			}
			h2 {
				margin: 0;
			}
			p, h2 {
				text-align: left;
				padding: 0 0 0 130px;

				@media ${device.laptopMedium} {
					width: 100%;
					padding-left: 0 !important;
					text-align: center !important;
					margin-top: 10px;
					font-size: 1.8rem;
				line-height: 2.6rem;
					margin-left: 0;
				}

				span {
					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 2.4rem;
						padding: 0 0 10px 0;
						width: 90%;
						margin: auto;
						margin-top: 16px;
						display: block;
					}
				}
			}
			a {
				color: #fff;
				padding: 10px 0 0 130px;
				position: relative;
				text-decoration: underline;
				text-underline-offset: 10px;
				cursor: pointer;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;

				@media ${device.laptopMedium} {
					width: 100%;
					padding-left: 0 !important;
					text-align: center !important;
					margin-top: 0;
					font-size: 1.8rem;
					line-height: 2.2rem;
					display: block;
				}

				&:hover {
					color: #00FFD9;
					text-decoration: none;
				}
			}
			.locator-link {
				padding: 0 0 0 30px;

				@media ${device.laptopMedium} {
					padding: 0;
				}
			}
		}

		.considerations-for-testing-primary-bg {
			position: relative;
		  margin: 5rem 0 10rem;

			@media ${device.laptopMedium} {
				margin: 5rem 0rem 10rem;
			}

			&:before {
				height: 60%;
			  top: 145px;
				content: "";
				display: block;
				background: #00FFD9;
				width: 100%;
				position: absolute;

				@media ${device.laptopMedium} {
					height: 70%;
				}
			}
		}

		.video-section {
			display: block;
			text-align: center;
			background-color: #8000BE;
			padding-left: 100px;
			padding-right: 100px;
			padding-top: 88px !important;

			@media ${device.laptopMedium} {
				padding-top: 48px !important;
				padding-left: 20px;
				padding-right: 20px;
				width: 100%;
				margin: 0 auto;
			}
      @media ${device.desktopsignup} {
        padding-left: 3.8rem;
			  padding-right: 3.8rem;
      }

			.video-section__inner-content {
				padding-bottom: 100px;
				text-align: center;

				@media ${device.laptopMedium} {
					padding-bottom: 30px !important;

				}
        @media ${device.desktopsignup} {
          padding-bottom: 30px;
        }
        p {
          &.o-paragraph {
            &.o-text--book {
              font-size: 1.8rem;
              line-height: 2.2rem;
              padding: 2rem 3rem 0;
              @media ${device.ipadLandscapemin} {
                font-size: 2.2rem;
                line-height: 3.2rem;
              }
            }
          }
        }
				h2 {
          font-size: 2.5rem;
          line-height: 1.2;
          font-weight: 700;
          padding-bottom: 0;
          color: #fff !important;
          font-family: ${font.gotham_bold};

					@media ${device.ipadLandscapemin} {
            font-size: 4.2rem;
            line-height: 5rem;
					}
          @media ${device.desktopsignup} {
            padding-bottom: 40px;
          }
				}
        .affordable-section {
          p {
            @media ${device.desktopsignup} {
              margin-bottom: 4rem;
            }
          }
        }

				p {
					margin: 0 auto 30px;
					font-size: 2.2rem;
					line-height: 3.2rem;
					color: #fff;
					text-align: center;
					max-width: 768px;
					font-family: ${font.gotham_book};
					font-weight: 500;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 1.22;
						padding-top: 1.9rem;
						margin: 0 !important;
					}

          &.disclaimer-para {
            padding: 3.8rem 2.2rem 2.6rem !important;

            @media ${device.desktopsignup} {
              padding-top: 0;
              padding-bottom: 1rem;
            }
          }
          &:last-child {
            padding-top: 1rem;
            @media ${device.desktopsignup} {
              padding-top: 0;
              margin-bottom: 0;
            }
          }

					small {
						font-size: 78%;
						font-weight: 400;
            line-height: 1.22;

            @media ${device.ipadLandscapemin} {
              font-size: 18px;
              line-height: 2.11;
            }

					}

					a {
						color: #fff;
						text-decoration: underline;
						text-underline-offset: 5px;
						cursor: pointer;
						font-family: 'Gotham';

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
						  line-height: 1.22;
							font-weight: 500;
						}

						&:hover {
							color: #00FFD9;
							text-decoration: none;
						}

						&:after {
							content: '';
							width: 20px;
							height: 20px;
							position: absolute;
							margin-top: 9px;
							margin-left: 5px;

							@media ${device.laptopMedium} {
								margin-top: 4px;
							}
						}
					}
				}
				.affordable-section {
					.media--image {
						display: none;
					}
				}
			}
		}

		.footer-block.footer-block-bg {
			background-color: #8000BE;
			padding: 0 100px;
			display: flex;

			@media ${device.laptopMedium} {
				display: block;
				margin-top: -1px;
				margin-bottom: -32%;
				margin-left: auto;
				margin-right: auto;
				padding: 0 46px;
				width: 100%;
			}

			@media  ${device.tablet} and ${device.laptopair2max} {
				margin-left: auto;
				margin-right: auto;
			}

			.footer-block--row {
				margin: auto;
				display: flex;

				@media ${device.laptopMedium} {
					display: block;
				}

				.footer-inner-block {
					border-radius: 10px;
					background-color: #fff;
					padding: 25px 20px 120px;
					position: relative;
					margin: 0 15px;
					z-index: 100;
					width: 50%;
					max-width: 449px;

					@media ${device.laptopMedium} {
						padding-bottom: 30px !important;
						width: 100%;
						margin: 30px auto 0;
						padding: 16px 0px 120px;
					}

					@media ${device.tablet} and ${device.ipadLandscape} {
						margin: 0 auto !important;
						display: table;
						max-width: unset;
					}
          h3 {
            &.o-common-title {
              font-size: 2rem;
              line-height: 2.4rem;
              font-family: ${font.gotham_bold};
              padding: 0 5px;
              @media ${device.ipadLandscapemin} {
                font-size: 2.7rem;
                line-height: 3.8rem;
              }
            }
          }
          p {
            &.o-paragraph {
              font-size: 1.8rem;
              line-height: 2.2rem;
              font-family: ${font.gotham_book};
              padding: 24px 5px 0;
              @media ${device.ipadLandscapemin} {
                font-size: 2.7rem;
                line-height: 3.8rem;
              }
            }
          }
          a {
            &.o-button {
              font-size: 1.8rem;
              line-height: 2.2rem;
              font-family: ${font.gotham};
              padding: 10px;
              margin: 32px auto 0;

              @media ${device.laptopMedium} {
                width: 85%;
              }
              @media ${device.ipadLandscapemin} {
                font-size: 2.2rem;
                line-height: 2.4rem;
                padding: 15px;
              }
            }
          }
					&:first-child {
						margin-left: 0;

						@media ${device.laptopMedium} {
							margin-left: auto;
							margin-top: 0;
						}
					}
					&:last-child {
						margin-right: 0;

						@media ${device.tablet} and ${device.ipadLandscape} {
							margin-top: 30px !important;
						}

						img {
							@media ${device.laptopMedium} {
								height: 85px !important;
							}
						}
					}
					&::before {
						content: "";
						background: #00FFD9;
						width: 100%;
						height: 16px;
						position: absolute;
						top: 0;
						left: 0;
						border-radius: 9px 9px 0 0;
					}

					img {
						margin: auto;
						text-align: center;
						display: block;
						padding: 10px 0 20px 0;
						height: 100px;
						width: auto;

						@media ${device.laptopMedium} {
							height: 100px;
							padding: 16px 0;
						}
					}

					h3 {
						min-height: 76px;
						font-size: 2.7rem;
						line-height: 3.8rem;
						color: #8000BE;
						text-align: center;
            font-family: ${font.gotham_bold};
						@media ${device.laptopMedium} {
							min-height: unset;
							font-size: 2rem;
						  line-height: 2.2rem;
							width: 90%;
							text-align: center;
							display: table;
							margin: 0 auto;
						}
					}

					p {
						text-align: center;
					  padding: 24px 0 0 0;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						color: #000000;
						font-family: 'Gotham-Book';

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
						line-height: 2.6rem;
						}
					}

					a {
						position: absolute;
						width: 90%;
						bottom: 25px;
						text-align: center;
						box-shadow: 0 3px 4px #d6d6d6;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 2rem;
						background: #8000BE;
						color: #fff;
						border: 1px solid #fff;
						font-weight: 500;
						line-height: 3.2rem;
						text-decoration: none;
						z-index: 1;
						vertical-align: top;
						overflow: hidden;
						padding: 10px 25px;
						letter-spacing: 0;
						border-radius: 6px;
						transition: all 0.3s ease;
					  cursor: pointer;
						font-family: 'Gotham';

						@media ${device.laptopMedium} {
							position: relative;
							left: 0;
							bottom: 0;
							margin-top: 32px;
							width: 100%;
							display: block;
							padding: 10px;
							font-size: 18px;
							line-height: 2.6rem;
						}
						@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
							margin-left: -6px;
							width: 93%;
						}

						&:hover {
							background: #fff;
							color: #8000BE;
							border-color: #fff;
							font-weight: 700;
						}

						&:after {
							content: '';
							width: 16px;
							height: 14px;
							display: inline-block;
							margin-left: 6px;
							position: relative;
							top: 2px;

							@media ${device.laptopMedium} {
								display: block;
								margin: 0 auto;
                width: 16px;
							}
              @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
                width: 21px;
              }
						}
					}
				}
			}
		}

	}
  #discussing-post-content {
    .o-container {
      @media ${device.laptopPros} {
        padding: 0 3rem;
      }
      @media ${device.tablet} and ${device.laptopMedium} {
        padding: 0 5rem;
      }
    }
    .footer-block {
      padding: 0 100px;
      display: flex;
      background-color: rgb(128, 0, 190);
      padding: 0px 100px;
      display: flex;
      margin-top: -1px;

      @media ${device.laptopMedium} {
        padding: 0 46px !important;
        display: block;
        margin-top: -1px;
      }

      .footer-block--row {
        margin: auto;
        display: flex;

        @media ${device.laptopMedium} {
          display: block;
        }

        .footer-inner-block {
          border-radius: 10px;
          background-color: #fff;
          padding: 25px 20px 120px;
          position: relative;
          margin: 0 15px;
          z-index: 100;
          width: 50%;
          max-width: 449px;
          box-shadow: 0 2px 2px rgb(17 17 17 / 16%);

          @media ${device.laptopMedium} {
            padding-bottom: 30px !important;
            width: 100%;
            margin: 30px auto 0;
            padding: 25px 15px 120px;
          }

          @media ${device.mobilemidmax} {
            max-width: 261px;
          }
          h3 {
            &.o-common-title {
              font-size: 20px;
              line-height: 24px;
              font-family: ${font.gotham_bold};
              @media ${device.ipadLandscapemin} {
                font-size: 27px;
                line-height: 38px;
              }
            }
          }
          p {
            &.o-paragraph {
              font-size: 1.8rem;
              line-height: 2.2rem;
              @media ${device.ipadLandscapemin} {
                font-size: 2.2rem;
                line-height: 3.2rem;
              }
            }
          }
          a {
            &.o-button {
              font-size: 1.8rem;
              line-height: 2.2rem;
              font-family: ${font.gotham};
              @media ${device.ipadLandscapemin} {
                font-size: 2.2rem;
                line-height: 2.4rem;
                padding: 12px;
              }
            }
          }
          &:first-child {
            margin-left: 0;

            @media ${device.laptopMedium} {
              margin-left: auto;
              margin-top: 0;
            }
            @media ${device.tablet} and ${device.laptopMedium}  {
              min-width: 526px;
            }


            &:before {
              background-color: #00ffd9;
               }

            img {
              @media ${device.laptopMedium} {
                height: 85px;
              }
            }

          }
          &:last-child {
            margin-right: 0;

            @media ${device.laptopMedium} {
              display: table;
              margin: 0 auto;
              margin-top: 30px;
            }
            @media ${device.tablet} and ${device.laptopMedium}  {
              min-width: 526px;
            }

            &:before {
              background: linear-gradient(90deg,#3398ce 0%,#3398ce 25%,#17d0d4 74%,#06f2d7 100%);
            }

            source {
              display: none;
            }
            .gatsby-image-wrapper-constrained {
              display: table;
              margin: 0 auto;

              >div {
                max-width: 70px !important;
              }
            }
          }

          &:before {
            content: "";
            width: 100%;
            height: 16px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 9px 9px 0 0;
          }

          source {
            margin: auto;
            text-align: center;
            display: block;
            padding: 10px 0 20px 0;
            height: 100px;
            width: auto;

            @media ${device.laptopMedium} {
              height: 100px;
            }
          }

          img {
            margin: auto;
            text-align: center;
            display: block;
            padding: 10px 0 20px 0;
            height: 100px;
            width: auto;

            @media ${device.laptopMedium} {
              height: 100px;
            }
          }
          h3 {
            font-weight: 500;
            min-height: 76px;
            font-size: 2.7rem;
            line-height: 3.8rem;
            color: #8000BE;
            text-align: center;

            @media ${device.laptopMedium} {
              min-height: unset;
              font-size: 2rem;
            line-height: 2.2rem;
            }
          }
          p {
            text-align: center;
            padding: 24px 0 0 0;
            font-family: 'Gotham-Book';
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            color: #000000;
            margin-bottom: 10px;

            @media ${device.laptopMedium} {
              font-size: 1.8rem;
            line-height: 2.6rem;
            }
          }
          a {
            position: absolute;
            width: 90%;
            bottom: 25px;
            text-align: center;
            box-shadow: 0 3px 4px #d6d6d6;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            background: #8000BE;
            color: #fff;
            border: 1px solid #fff;
            font-weight: 500;
            line-height: 3.2rem;
            text-decoration: none;
            z-index: 1;
            vertical-align: top;
            overflow: hidden;
            padding: 10px 22px;
            letter-spacing: 0;
            border-radius: 6px;
            transition: all 0.3s ease;
            cursor: pointer;

            @media ${device.laptopMedium} {
              position: relative;
              left: 0;
              bottom: 0;
              margin-top: 32px;
              width: 100%;
              display: block;
              padding: 10px;
              font-size: 18px;
            }

            &:hover {
              background: #fff;
              color: #8000BE;
              border-color: #fff;
              font-weight: 700;
            }

            &:after {
              content: '';
              width: 21px;
              height: 14px;
              display: inline-block;
              margin-left: 6px;
              position: relative;
              top: 2px;

              @media ${device.laptopMedium} {
                display: block;
                margin: 0 auto;
              }

            }
          }
        }
      }
    }
  }
  .o-top-space {
    .tab-section {
      .card {
        .tabbable-responsive {
          @media ${device.laptopMedium} {
            padding: 0;
          }
          .nav-tabs {
            max-width: 1109px;
            margin: 0 auto;
            min-width: auto;
            padding: 0 3rem;
            @media ${device.tablet} and ${device.laptopMedium} {
              padding: 0 8.5rem;
            }
            @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
              padding: 0 11.5rem;
            }
            @media ${device.desktopStart} {
              padding: 0;
            }
          }

        }
      }
    }
  }
  .cft-tab-comp {
    .tab-section {
      padding: 110px 0 0 0;
      @media ${device.laptopMedium} {
        padding: 82px 0 0 0;
      }
      @media ${device.tablet} and ${device.laptopair2max} {
        width: 100%;
      }

      .card {
        @media ${device.laptopMedium} {
          padding-bottom: 0px;
        }
        .tabbable {
          .tab-content {
            .generic-slider-no-image {
              .swiper-pagination {
                .swiper-pagination-bullet {
                  color: #8000BE !important;

                  &.active {
                    background-color: #8000BE !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .carouselSlides {
    @media ${device.laptopPros} {
      padding: 0 2.7rem;
    }
    @media ${device.tablet} and ${device.laptopMedium} {
      padding: 0 8.5rem;
    }
    @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
      padding: 0 9rem;
    }
    >div.o-container {
      @media ${device.laptopMedium} {
        padding: 0;
      }
    }
  }
  .tabbable-responsive {
    overflow: hidden !important;
    width: 100%;
    > .o-container {
      max-width: 1208px !important;
      padding: 0;
      @media ${device.desktopsignup} {
        max-width: 100% !important
      }
    }

    @media ${device.laptopMedium} {
      overflow-x: hidden !important;
      padding: 0 1rem;
      min-width: 100% !important;
    }

    .tabbable {
      ul {
        li {
          button {
            p {
              @media ${device.laptopair2max} {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .slick-slide {
      @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
        margin: 0;
      }
    }

    .nav-tabs {
      @media ${device.laptopMedium} {
        margin-bottom: 30px !important;
      }
      .nav-link {
        @media ${device.laptopMedium} {
          font-size: 1.8rem;
          line-height: 2rem;
          border-color: #dee2e6 #dee2e6 #fff;
        }
        &:focus {
          outline: 1px auto #000;
        }
        br {
          display: none;

          @media ${device.laptopMedium} {
            display: block;
          }
        }

        p {
          margin-bottom: 0;
          br {
            display: none;

            @media ${device.laptopMedium} {
              display: block;
            }
          }
        }
      }
    }
    .slick-slide {
      @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
        margin: 0 !important;
      }
    }
    #tabbed-content-tabpane-tab0 {
      .video-container-consideration-for-testing {
        max-width: 1188px;

        @media ${device.desktopStart} {
          padding: 0 1rem;
        }
        .video-section__inner-content {
          p {
            &.extlink-text {
              max-width: 100%;
              padding: 19px 10px 0;
              @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                padding: 19px 20px 0;
              }
            }
          }
        }
      }
      .carouselSlides {
        margin-top: 40px;
        @media ${device.laptopPros} {
          padding: 0 3rem;
        }
        @media ${device.laptopMedium} {
          margin-top: 0;
        }
        .btn-wrapper-slider {
          margin-top: 50px;
          padding-bottom: 0;
          margin-bottom: 0;
          width: 100%;
        }
        .slick-slide {
          .result-main-section {
            .form-card {
                img {
                  @media ${device.laptopMedium} {
                    width: 30px;
                    margin-bottom: 16px;
                  }
                }
              .slide-content {
                p {
                  &.o-paragraph {
                    font-size: 1.8rem;
                    line-height: 2.2rem;
                    @media ${device.ipadLandscapemin} {
                      font-size: 2.2rem;
                      line-height: 3.2rem;
                    }
                  }
                }
                ul {
                  padding-top: 0;
                  @media ${device.laptopPros} {
                    padding-left: 3px;
                  }
                  @media ${device.tablet} and ${device.laptopMedium} {
                    padding-left: 4.1px;
                  }
                  @media ${device.ipadLandscapemin} {
                    padding: 0 1rem 0;
                  }
                  li {
                    &.o-column--full {
                      font-size: 1.8rem;
                      line-height: 2.2rem;
                      @media ${device.ipadLandscapemin} {
                        font-size: 2.2rem;
                        line-height: 3.2rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .swiper-pagination {
        @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
          width: 94%;
        }
        span {
          &:before {
            @media ${device.laptopMedium} {
              top: -3px;
              position: relative;
              font-weight: inherit;
              line-height: inherit;
              font-size: inherit;
              width: inherit;
              height: inherit;
            }
          }
        }
        .swipper-texts1::before {
          content: "1";
          @media ${device.ipadLandscapemin} {
            top: -15px;
            position: relative;
          }
        }
        .swipper-texts2::before {
          content: "2";
          @media ${device.ipadLandscapemin} {
            line-height: 4.7px;
            font-weight: 700;
          }
        }
        .swipper-texts3::before {
          content: "3";
          @media ${device.ipadLandscapemin} {
            line-height: 4.7px;
            font-weight: 700;
          }
        }
        .swipper-texts4::before {
          content: "4";
        }
        .swipper-texts5::before {
          content: "5";
        }
        .swiper-pagination-bullet {
          color: #8000BE !important;

          &.active {
            background-color: #8000BE !important;
          }
        }
      }
      span.swiper-pagination-bullet {
        &:first-child {
          &:after {
            content: "Clinically examine your patient";
          }
        }
        &:nth-child(2) {
          &:after {
            content: "Partner with a genetic counselor or genetic specialist";
          }
        }
        &:nth-child(3) {
          &:after {
            content: "Order the appropriate genetic test";
          }
        }
        &:nth-child(4) {
          &:after {
            content: "Collect a saliva or blood sample";
            max-width: 196px;
          }
        }
        &:nth-child(5) {
          &:after {
            content: "Discuss the results with your patient";
          }
        }
        &:after {
          @media ${device.tablet} and ${device.laptopair2max} {
            margin-left: 18px;
            width: 179px;
          }
          @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
            margin-left: 18px;
            width: 179px;
          }
        }
        &:after {
          line-height: normal;
        }
      }
    }
    #tabbed-content-tabpane-tab1 {
      .video-container-consideration-for-testing {
        @media ${device.minimumDesk} {
          max-width: 1208px;
        }
        @media ${device.desktopsignup} {
          padding-left: 5rem;
          padding-right: 5rem;
        }
        @media screen and (min-width: 1365px) {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
      .video-section__inner-content {
        p {
          @media ${device.desktopsignup} {
            padding-top: 0;
          }
            &.o-paragraph {
              font-size: 1.8rem;
              line-height: 2.2rem;
              max-width: 768px;
              @media ${device.ipadLandscapemin} {
                font-size: 2.2rem;
                line-height: 3.2rem;
              }
            }
        }
        .affordable-section {
          p {
            a {
              &:after {
                @media ${device.ipadLandscapemin} {
                  margin-top: 4px;
                }
              }
            }
          }
          p {
            &.extlink-text {
              max-width: 100%;
              padding: 19px 10px 0;
              @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
                padding: 19px 20px 0;
              }
            }
          }
          @media ${device.desktopsignup} {
            padding-top: 3.3rem;

            p {
            padding-bottom: 4rem;
            }

          }
        }
        .disclaimer-para {

          @media ${device.desktopsignup} {
            padding-bottom: 3.7rem;
            font-size: 2.2rem;
            line-height: 1.45;
          }
        }
      }
        .slick-slide {
          .result-main-section {
            @media ${device.laptopMedium} {
              padding-top: 80px;
            }
            ul {
              li {
                &:first-child {
                  @media ${device.ipadLandscapemin} and ${device.desktopmenu}{
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
        .carouselSlides {
          @media ${device.mobilemax} {
            padding: 0 2.75rem !important;
          }
          @media ${device.laptopPros} {
            padding: 0 3rem;
          }
          .btn-wrapper-slider {
            display: none;
            margin-top: 0;
          }
        }

      .generic-slider-no-image {
        .swiper-pagination {
          width: 62%;
          @media ${device.mobilemax} {
            width: 98.5% !important;
          }
          @media ${device.laptopMedium} {
            width: 100%;
          }
          @media ${device.desktopsignup} {
            width: 53%;
          }
          @media ${device.desktopStart} {
            width: 57%;
          }
        }
      }

      .form-card {
        @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
          width: 91%;
        }
        h3 {
          &.o-title-text {
            @media ${device.ipadLandscapemin} {
              line-height: 3.8rem;
            }
          }
        }
      }

      span.swiper-pagination-bullet {
        &:first-child {
          &:after {
            content: "Before the genetic test";
          }
        }
        &:nth-child(2) {
          &:after {
            content: "After the genetic test";
          }
        }
        &:nth-child(3) {
          &:after {
            content: "If a genetic test is negative or inconclusive";
          }
        }
        &:after {
          line-height: normal;
        }
      }
      span {
        p {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 2.2rem;
          color: #fff;
          text-align: center;
          padding-top: 19px;
          max-width: 100%;
          margin: 0 auto;
          font-family: ${font.gotham_book};

          @media ${device.laptopPros} {
            font-size: 18px;
            line-height: 22px;
            font-weight: 500;
            margin-bottom: 5px;
          }

          @media ${device.desktopStart} {
            max-width: 1208px;
            margin: 0 auto;
          }
        }
        .tab2-heading-style {
          color: #000;
          font-size: 2.5rem;
          font-family: ${font.gotham_bold};
          line-height: 3rem;
          padding: 0px 3.5rem;
          text-align: center;
          @media ${device.ipadLandscapemin} {
            font-size: 4.2rem;
            font-weight: 500;
            line-height: 5rem;
            padding: 4rem 10rem;
          }
          @media ${device.desktopsignup} {
            padding: 4rem 25rem;
          }
        }
        li.o-column--full {
          p {
            color: #000;
            font-size: 1.8rem;
            line-height: 2.2rem;
            font-family: ${font.gotham_book};
            padding-top: 0;
            text-align: left;
            @media ${device.ipadLandscapemin} {
              font-size: 2.2rem;
              line-height: 3.2rem;
            }
          }
        }
        .genes-faq-content__left {
          .o-paragraph {
            color: #000;
            font-size: 1.8rem;
            line-height: 2.2rem;
            font-family: ${font.gotham_book};
            padding-top: 0;
            text-align: center;
            @media ${device.ipadLandscapemin} {
              font-size: 2.2rem;
              line-height: 3.2rem;
              text-align: left;
            }
          }
        }

          sup {
            font-size: 60%;
          }
        }
      }
      .result-title {
        position: absolute;
        font-weight: 500;
        font-size: 2.7rem;
        line-height: 3.4rem;
        padding-bottom: unset;
        color: #8000BE;

        @media ${device.laptopMedium} {
          text-align: center;
          font-size: 22px;
          line-height: 26px;
          position: relative;
        }

        .black-font {
          color: #000000;
        }
      }
      img {
        @media ${device.laptopMedium} {
          position: relative;
          width: 50px;
          margin-bottom: 20px;
        }
      }
      #discuss-1 {
        ul {
          padding-top: 50px;
          @media ${device.ipadLandscapemin} {
            padding-left: 0;
          }
        }
      }
      #discuss-2 {
        ul {
          @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
            padding-top: 95px;
          }
        }
      }

      ul {
        padding-top: 75px;
        padding-left: 0;

        @media ${device.laptopMedium} {
          padding-top: 20px;
        }
      }
      .btn-wrapper-slider {
        width: 85%;
        margin: 0 auto;
        display: flex;
        padding-bottom: 20px;

        @media ${device.ipadLandscapemin} {
          position: relative;
          justify-content: center;
          margin-top: 15px;
          margin-bottom: 30px;
          padding-bottom: 50px;
        }

        .tab-button1 {
          width: auto;
          overflow: hidden;
          padding: 35px 25px;
          box-shadow: 0 3px 4px #d6d6d6;
          text-decoration: none;
          line-height: 3.3rem;

          @media ${device.laptopMedium} {
            margin: 0px auto 15px;
            font-size: 1.8rem;
            line-height: 2.6rem;
            display: block;
            height: auto;
            width: 100%;
            text-align: center;
            max-width: 281px;
            padding: 13px 20px;
          }

          @media ${device.tablet} and ${device.laptopair2max} {
            width: 56%;
            max-width: 281.62px;
          }
          @media ${device.desktopsignup} {
            padding: 27px 22.5px;
          }

          &:after {
            content: '';
            width: 21px;
            height: 14px;
            display: inline-block;
            margin-left: 6px;
            position: relative;
            top: 1px;
            @media ${device.laptopMedium} {
              margin: 0 auto;
              display: block;
              width: 16px;
            }
          }
        }
      }
      .swiper-pagination {
        span {
          color: transparent !important;

          &:before {
            content: "";
            display: inline-block;
            color: rgb(128, 0, 190);
          }
          &:first-child {
            &:before {
              width: 18px;
              height: 14px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
          &:nth-child(2) {
            &:before {
              content: "+";
              font-weight: 500;
              line-height: 40px;
              font-size: 3.4rem;
              color: #8000BE;
            }
          }
          &:nth-child(3) {
            &:before {
              content: "?";
              font-weight: 500;
              line-height: 40px;
              font-size: 3.4rem;
              color: #8000BE;

              @media ${device.laptopMedium} {

              }
            }
          }
          &.active {
            &:before {
              color: #fff !important;
            }
          }
        }
      }
    }
    div.step-to-gen.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
      span.swiper-pagination-bullet {
        @media ${device.laptopMedium} {
          margin: -5px !important;
        }
      }
    }

    .step-to-gen.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
      max-width: 906px !important;

      span.swiper-pagination-bullet {
        width: 60px !important;
        height: 60px !important;
        background-color: #7323CA;
        margin: 0px !important;
        opacity: 1;
        color: #8000BE;
        position: relative;
        display: flex;
        align-items: center;
        justify-items: center;
        border-radius: 100%;
        font-weight: 700;
        line-height: 40px;
        font-size: 3.4rem;
        display: block;
        margin: auto;

        @media ${device.laptopMedium} {
          width: 40px !important;
          height: 40px !important;
          margin: -5px !important;
        }

        &.active {
          background-color: rgb(115, 35, 202) !important;
          color: #fff !important;
        }

        &:after {
          font-size: 2.2rem;
          color: #8000BE;
          position: absolute;
          top: 70px;
          left: -81px;
          width: 211px;
          font-weight: 500;
          line-height: 3.2rem;

          @media ${device.laptopMedium} {
            display: none;
          }
        }
      }
    }
    .swiper-progress-bar-fill {
      position: absolute !important;
      height: 8px;
      z-index: -1;
      background-color: rgb(115, 35, 202) !important;
    }
    .slick-list {
      margin: 0 35px;
      @media ${device.laptopMedium} {
        margin: 0;
      }
      @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
        margin: 0;
      }
    }
    .slick-active {
      @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
        margin: 0 !important;
      }
    }
    .slick-slide {
      max-width: 950px;
      margin: 0 70px;
      margin-right: 70px;
      margin-left: 12px;

      @media ${device.laptopMedium} {
        margin: 0;
      }

      @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
        margin-left: -1px !important;
        margin-right: 0px !important;
      }

      >div {
        margin-left: 32px;
        margin-right: 32px;
        width: 100%;

        @media ${device.laptopMedium} {
          margin-left: 0;
          margin-right: 0;
        }

        @media ${device.laptopair2max} {
          margin-left: 0;
          margin-right: 0;
        }
      }

      .result-main-section {
        padding-top: 245px;

        @media ${device.laptopMedium} {
          padding-top: 65px;
        }

        @media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
          padding: 265px 2.2rem 0;
        }

        .wizard-title {
          display: none;
          @media ${device.laptopMedium} {
            display: block;
            color: #8000BE;
            font-size: 1.8rem;
            line-height: normal;
            margin-bottom: 20px;
            text-align: center;
          }
        }
        .form-card {
          padding-left: 100px;
          padding-bottom: 30px;
          display: flex;
          position: relative;

          @media ${device.laptopMedium} {
            display: block;
            padding-bottom: 10px;
            padding-left: 0;
          }

          img {
            top: 0;
            position: absolute;
            left: 0;
            vertical-align: middle;
            border-style: none;
            margin: auto;
            display: block;

            @media ${device.laptopMedium} {
              position: relative;
              margin: auto;
              width: 50px;
              margin-bottom: 20px;
            }
          }

          p {
            padding: 0 0 20px 10px;
            line-height: 3rem;
            color: #000000;
            text-align: left;
            font-family:'Gotham-Book';
            font-size: 2.2rem;
            font-weight: 500;

            @media ${device.laptopMedium} {
              padding: 0 0 10px 0;
              text-align: center;
              font-size: 1.8rem;
            }

            @media ${device.ipadLandscapemin} and ${device.laptopair2max} {
              text-align: center;
            }

            strong {
              font-family: 'Gotham';
            }
            sup {
              font-size: 60%;
            }
          }
        }
        ul {
          margin-top: 0;
          margin: auto;
          display: grid;

          @media ${device.laptopMedium} {
            padding-left: 1px;
          }

          li {
            padding: 8px 0 8px 25px;
            line-height: 3.2rem;
            display: inline-block;
            position: relative;
            font-size: 2.2rem;
            font-weight: 500;
            list-style: none;
            font-family:'Gotham-Book';

            @media ${device.laptopMedium} {
              font-size: 1.8rem;
            }
            &:before {
              content: "";
              position: absolute;
              background-color: #8000BE;
              width: 8px;
              height: 8px;
              top: 20px;
              margin: auto;
              left: 0;
            }
            sup {
              font-size: 60%;
            }
          }
        }

        .slider-end-copy {
          color: #8000BE;
          line-height: 3.2rem;
          font-size: 2.2rem;

          @media ${device.laptopMedium} {
            font-size: 18px;
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }

    .btn-wrapper-slider {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .button {
        width: 145px;
        height: 48px;
        background: #8000BE;
        margin: 0px 15px 30px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        font-size: 2.2rem;
        color: #fff;
        border: 1px solid #fff;
        box-shadow: 0 3px 4px #d6d6d6;
        cursor: pointer;

        @media ${device.laptopMedium} {
          margin: 0px 8px 30px;
        }

        &:hover {
          background: rgb(255, 255, 255);
          color: rgb(128, 0, 190);
          border-color: rgb(255, 255, 255);
          font-weight: 700;
        }
        span {
          line-height: 2.4rem;
          font-weight: 500;
        }
      }
      .forward-btn {
        &:after {
          content: "";
          display: inline-block;
          height: 17px;
          width: 17px;
          margin-left: 4px;
        }
      }
      .back-btn {
        &:before {
          content: "";
          display: inline-block;
          height: 17px;
          width: 17px;
          margin-right: 4px;
          transform: rotate(-180deg);
        }
      }
    }

    .slider-end-paragraph {
      text-align: center;
      line-height: 3.2rem;
      font-family: 'Gotham-Book';
      font-size: 2.2rem;
      font-weight: 500;
      margin-bottom: 10px;
      max-width: 1109px;
      padding-bottom: 138px;

      @media ${device.laptopMedium} {
        font-size: 1.8rem;
        line-height: 2.2rem !important;
        padding-bottom: 48px !important;
        padding: 0 3rem;
        margin-bottom: 10px;
      }
      @media ${device.tablet} and ${device.laptopMedium} {
        padding: 0 7.5rem;
      }
      @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
        margin: 0 11.2rem 10px;
      }
      @media ${device.desktopsignup} {
        margin: 0 auto 10px;
      }
    }
  }
  .consideration-testing {
    &:before {
      @media ${device.largeS} {
        left: 30px;
      }
    }
  .o-header--h1 {
      p {
        @media ${device.ipadLandscape} {
          padding-left: 0;
        }
      }
  }

  .internal-banner__contant {
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;

      @media ${device.ipadLandscape} {
        top: 40px;
        transform: translateY(0);
      }
  }

  .o-container-considerations {
      max-width: 1188px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 3rem;

      .c-genetic--american {
        padding: 35px 0px;
        margin-bottom: 8rem;
        margin-top: 8rem;
        max-width: 950px;
        margin-left: auto;
        margin-right: auto;

        .o-paragraph {
          font-size: 2.5rem;
          font-weight: 600;
          line-height: 3.2rem;
          color: #000000;
          text-align: center;
        }
      }

      .c-genetic--test {
        padding: 35px 60px;
        position: relative;
        background-color: #f7f7f7;
        box-shadow: 0 4px 6px #cecece;
        margin-bottom: 8rem;
        margin-top: 8rem;
        max-width: 950px;
        margin-left: auto;
        margin-right: auto;
        margin: 0 70px;

        .o-paragraph {
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;
          color: #000000;
        }

        .o-paragraph-inner {
          font-size: 2.2rem;
          font-weight: 500;
          line-height: 3.2rem;
          color: #000000;

          span {
            font-weight: bold;
          }
        }

        .o-text-link {
          text-transform: unset;
          font-size: 2.2rem;
          line-height: 3.2rem;
          text-decoration: none;
          position: relative;
          display: inline-block;
          color: #000000;
          font-weight: 700;
          padding-bottom: 3px;
          margin-top: 14px;
        }

        p {
          a {
            color: #8000BE;
            font-weight: 500;
          }
        }

        .thirdPartyLink {
          cursor: pointer;
        }

        .o-text-link {
          a {
            color: #8000BE;
            text-decoration: underline;
          }

        }

        @media ${device.ipadLandscape} {
          margin: 40px 0 0;
          padding: 20px;
        }

    }

      .c-genetic--test::before {
        content: "";
        width: 16px;
        height: 100%;
        display: block;
        background: linear-gradient(-180deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
        position: absolute;
        top: 0;
        right: 100%;
        box-shadow: 0 4px 6px #cecece;

        @media ${device.ipadLandscape} {
          content: "";
          width: 100%;
          height: 10px;
          background: linear-gradient(-188deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
          top: 0;
          left: 0;
          right: unset;
          box-shadow: none;
        }
      }

      @media ${device.midDesktopMax} {
        padding: 0 5rem;
      }

      @media ${device.ipadLandscape} {
        max-width: 718px;
        font-size: 2rem;
        line-height: 2.6rem;
      }

      @media ${device.laptopPros}{
        padding: 0 3rem;
      }
  }
  }
  .emerging-science-when-you-test {
    margin: 120px auto 0;

  @media ${device.laptopPros} {
    padding: 0 2.7rem;
  }
  .o-inner {
    padding: 0 100px;

    @media ${device.laptopMedium} {
      padding: 0;
    }

      .specialist-infobox.info-box {
        margin-bottom: 120px;
        padding: 32px 80px;

        @media ${device.laptopMedium} {
          margin-bottom: 28px;
          padding: 24px 42px;
        }

        .media--image {
          @media ${device.minimumDesk} {
            padding-bottom: 80px;
          }
        }
      }

      .info-box {
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #300064;
        padding: 27px 102px;
        position: relative;
        margin: auto;
        width: 100%;
        text-align: center;

        @media ${device.laptopMedium} {
          display: block;
        }

        img {
          position: absolute;
          left: 100px;
          max-width: 100%;

          @media ${device.laptopMedium} {
            display: block;
            margin: 0 auto 10px;
            width: 45px;
            left: 0;
            right: 0;
            text-align: center;
            position: unset;
            top: 25px;
          }
        }

        .o-paragraph {
          padding: 0 0 0 120px;
          font-family: 'Gotham-Book';
          font-weight: 700;
          text-align: left;
          color: #fff;
          line-height: 3.2rem;
          font-size: 2.2rem;
          margin: 0;

          @media ${device.laptopMedium} {
            padding: 0;
            font-family: 'Gotham-Book';
            line-height: 2rem;
            font-size: 2rem;
            display: inline-block;
            text-align: center;
            margin-top: 10px;
          }

          strong {
            color: #00FFD9;
            display: inline-block;
            font-size: 2.7rem;
            padding-bottom: 12px;
            font-weight: 600;

            @media ${device.laptopMedium} {
              line-height: 2.2rem;
              padding-bottom: 32px;
              font-size: 2rem;
            }
          }

        }

        .link-right-arrow {
          cursor: pointer;
          background-color: #fff;
          width: 40px;
          height: 40px;
          line-height: 46px;
          text-align: center;
          border-radius: 50%;
          margin: 5px 0 0 28px;

          @media ${device.laptopMedium} {
            display: flex;
            margin: 20px auto 0;
            justify-content: center;
            align-items: center;
            position: initial;
          }

          &:hover {
            transform: scale(1.2);
            transition: ease 5ms;
          }

          img {
            display: block;
            margin: 13px auto;
            max-width: 100%;
            position: static;
            left: 0;

            @media ${device.laptopMedium} {
              margin: 0;
              width: auto;
            }
          }
        }
      }
    }
  }
  .c-gene-modification {
    padding-bottom: 0px;
    padding-top: 60px;

    @media ${device.ipadLandscape} {
      text-align: center;
      padding: 0 !important;
      padding-bottom: 0 !important;
    }

  .faq-block {
      flex-wrap: inherit;
      align-items: center;
      margin: 0 auto !important;
      max-width: 1440px;
      padding-top: 44px;
      display: flex;
      position: relative;

      @media ${device.ipadLandscape} {
        display: block;
      }

      .genes-faq-content__left {
        width: calc(50% - 30px);
        margin: 0 30px 0 0;

        @media ${device.ipadLandscape} {
          width: 100%;
          margin: 0;
          padding-left: 25px;
          padding-right: 25px;
        }

        h2 {
          text-align: left;
          margin: 0;
          color: #000000;
          font-family: ${font.gotham_bold};
          font-size: 4.2rem;
          line-height: 5rem;
          @media ${device.ipadLandscape} {
            margin-top: 0px !important;
            font-size: 2.5rem;
            padding-bottom: 20px;
            line-height: 3rem;
            text-align: center;
          }
        }
        .gatsby-image-wrapper {
          width: 100%;
          img {
            bottom: 0;
            height: 100%;
            left: 0;
            margin: 0;
            max-width: none;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
          }
        }
      }

      .genes-faq-media__right {
        display: grid;
        align-items: center;
        width: 50%;

        @media ${device.ipadLandscape} {
          width: 100%;
          padding-top: 20px;
          padding: 20px 0 0;

          .gatsby-image-wrapper {
            width: 100%;
            vertical-align: middle;
            border-style: none;
            img {
              bottom: 0;
              height: 100%;
              left: 0;
              margin: 0;
              max-width: none;
              padding: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;
            }
          }
        }
      }

      .o-header--h2 {
        font-size: 4.2rem;
        font-weight: 600;
        line-height: 5rem;
        color: #000000;
      }

      .o-padding-left-full {
        @media ${device.ipadLandscape} {
          padding-left: 25px;
        }
        @media ${device.ipadLandscapemin} and ${device.ipadair2} {
          padding-left: calc((100% - 920px) / 2);
        }
        @media (min-width: 1086px) and ${device.miniTopDeskMax} {
          padding-left: calc((100% - 1015px) / 2);
        }
        @media ${device.minimumDesk} and ${device.desktopmenu} {
          padding-left: calc((100% - 1070px) / 2);
        }
        @media ${device.desktopsignup} {
          padding-left: calc((100% - 1142px) / 2);
        }
      }

      h2 {
        color: #000000;
        text-align: left;
        padding-bottom: 40px;
      }

      .o-text--book {
        font-family: 'Gotham-Book';

      }

      .o-paragraph {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.2rem;
        color: #000000;
        text-align: left;

        @media ${device.ipadLandscape} {
          font-size: 1.8rem;
          line-height: 2.6rem;
          text-align: center;
        }

        sup {
          font-size:60%;
        }
      }

      a {
        color: #8000BE;
        text-decoration: underline;

        &:hover {
          font-weight: bold;
        }
      }
  }
  }
  .video-container-consideration-for-testing {
    padding-top: 88px;
    max-width: 1208px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .consideration-footer-o-container {
    &.o-container {
      max-width: 1188px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 3rem;
      padding-right: 3rem;

      @media ${device.desktopsignup} {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  .footer {
      @media ${device.mobileMedium} {
        margin-top: 0;
      }

      @media ${device.ipadLandscape} {
        margin-top: 0;
      }
  }
`;
