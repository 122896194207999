import React, { useState, useEffect } from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout"
import { graphql } from 'gatsby'
import { PageWrapper } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import HTMLRenderer from '@retina-packages/retina-theme-bootstrap/packages/components/HTMLRenderer'
import {
  ConsiderationsForTesting
} from './styles'
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder, tabDataBuilders, footRefHTMLBuilder } from '../../../utils'
import TabbedContent from '@retina-packages/retina-theme-bootstrap/packages/components/TabbedComponents'
import retinaConfig from '../../../utils/retinaConfigs'
import { hcpCFT } from './constants'
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles'


/**
 * Rendering Considerations for Testing page template
 *
 * @param props Props
 * @returns  void
 */
const ConsiderationsTestingTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso

  const pgContextsCFT = props.pageContext
  const htmlStaticFilesCFT = {
    nonSVGImages: pgContextsCFT.nonSVGImages,
    svgMediaImages: pgContextsCFT.svgMediaImages,
    allMediaDocument: pgContextsCFT.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpCFT.mainMenu, siteFooterMenu: hcpCFT.footerMenu, addHCPPrefix: retinaConfig.addHcpPrefix });

  // Reusable properties building
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  //Banner Component data
  const bannerTitle = ""
  const carouselTitle = ""
  const footerTitle = hcpCFT.footer
  const exitPopupTitle = hcpCFT.exitPopup
  const hcpSwitcherTitle = hcpCFT.hcpSwitcher
  const topNavTitle = hcpCFT.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle })

  const hcpHomeReference = footRefHTMLBuilder({ blocks: blocks, title: hcpCFT.footerReference })
  const siteLogos = deriveSiteLogo({ blocks: blocks, title: hcpCFT.siteLogo })
  const bannerHTML = fullHTMLDataBuilder({ blocks: blocks, title: hcpCFT.banner })
  const pagewrapper = "consideration-testing"


  // Tabbed content
  const tabTitle = hcpCFT.tabPara
  const {
    tabArr,
    tabHead
  }:
    {
      tabHead: never[]
      tabArr: any[]
    } = tabDataBuilders({ blocks, tabTitle })

  const tabData = {
    tabData: tabHead,
    tabContent: tabArr
  }

  const [renderToggle, setRenderToggle] = useState(false)

  useEffect(() => {
    window.addEventListener('hashchange', () => {
      // Rerender the components for exit popup in second tab.
      if (window.location.hash === '#tab1') {
        setRenderToggle(!renderToggle)
      }
    })
    if (window.location.hash === '#tab1') {
      // Rerender the components for exit popup in second tab.
      setRenderToggle(!renderToggle)
    }
  }, [])

  return (
    <>
      <div className='hcppage'>
        {/* site is not accessible it shows "Access denied" error message */}
        <ConsiderationsForTesting className="hcpmobilewrapper hcp-consideration-container">
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            location={props.location}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            footref={hcpHomeReference}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            staticMediaFiles={htmlStaticFilesCFT}
            siteLogos={siteLogos}
            backToTopContent={retinaConfig.backToTopContent}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            toggleRender={renderToggle}
            hcpValidate={retinaConfig.hcpValidate}
            gtmBackToTopLabel={hcpCFT.backToTopGTM}
            preIndexUrl={retinaConfig.preIndexUrl}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={hcpCFT.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            {... { ...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
          >
            <PageWrapper className={`pagewrapper ${pagewrapper}`}>
              <MobileBanner className="mobile-banner clinical-trials-banner">
                <HTMLRenderer data={htmlStaticFilesCFT} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
              </MobileBanner>
              <div className='o-top-space cft-tab-comp'>
                <TabbedContent
                  stepContent={hcpCFT.stepContent}
                  data={tabData}
                  location={props.location}
                  mediaStaticFiles={htmlStaticFilesCFT}
                  onTabChange={setRenderToggle}
                  toggleRender={renderToggle}
                  {...hcpCFT.tabAttrs}
                />
              </div>
            </PageWrapper>
          </Layout>
        </ConsiderationsForTesting>
      </div>
    </>
  )
}

export default ConsiderationsTestingTemplate

export const pageQuery = graphql`

  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      drupal_internal__nid
      relationships {
        ... CFTQuery
      }
    }
  }
`